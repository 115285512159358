import { ToastContainer } from 'react-toastify';
import loading from '../../src/assets/images/loading.gif'
import { useLocation, useNavigate } from "react-router-dom";
import { logoutTepngUser } from '../request';
import Modal from "react-modal"
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { customStyles, formatWithCommas } from 'helpers';
import { PageProps } from 'interfaces/index.interface';

const Header = (props: PageProps) => {
    const { page } = props  
    const location = useLocation()
    const navigate = useNavigate();   
    const user:any = useSelector((state: any) => state.tepngUser.value);
    const roles:any = useSelector((state: any) => state.roles.value);    
    const unreadCount:number = useSelector((state: any) => state.unreadCount.value);    
    const [isLoading, setIsLoading] = useState(false)
    
    const logout = () => {
        setIsLoading(true)
        logoutTepngUser().then(res => {
            if(res) navigate('/logout'); setIsLoading(false)
        }) 
    }

    const role = window.location.pathname.split('/')[1]
    const AllowedToViewNotifications = [
        "transitofficer",
        "freightforwarder",
        "localclearingagent",
        "supplier"
    ]   

    return (
        <>
            <nav>
                <div className='nav-left'>
                    <div onClick={() => (document.getElementById("thenavbar") as HTMLElement)?.classList.toggle("active")} className="menu_btn"><span className="material-symbols-rounded" style={{color:"white"}}>menu</span></div>
                    <h4>{ page }</h4>
                </div>
               
                <div className='nav-right'>
                    <div>
                        <p className='capitalize'>{user?.firstName} {user?.lastName}</p>
                        <p className='uppercase' style={{fontSize: "10px"}}>{roles?.join(", ")}</p>
                    </div> 
                    <div className='user-manual'
                        title='Click to view user manual'>
                        User Manual
                    </div>
                    {AllowedToViewNotifications.includes(role) && <button type="button" className="icon-button" style={{borderLeft: "1px solid white", padding: "0 16px"}}  onClick={() => navigate(`/${location.pathname.split('/')[1]}/notifications`)}>
                        <span className="material-symbols-rounded">notifications</span>
                        {unreadCount > 0 && <span className="icon-button__badge">{formatWithCommas(unreadCount)}</span>}
                    </button>}
                    <button className='white-text' style={{borderLeft: "1px solid white", paddingLeft: "16px", height: "30px"}} 
                        onClick={() => logout()}>Logout</button>
                </div>
            </nav>
            <ToastContainer />
            <Modal isOpen={isLoading} style={customStyles} className="modal modal-sm" ariaHideApp={false}>
                <div className="loader">
                    <img src={loading} alt="loading" />
                    <p>Loading data...</p>
                </div>
            </Modal> 
        </>
    )
}

export default Header
import html3pdf from 'html3pdf';
import { currentDatetime, formatDateTime } from '../../helpers';

const PdfGenerator = (props) => {
  const { proofOfCollection, selectedPackage } = props
  const purchaseOrderItems = selectedPackage?.purchaseOrderItemSupplies?.map((record) => ({
    purchaseOrderNumber: record.purchaseOrderItem.purchaseOrderNumber,
    purchaseOrderItemNumber: record.purchaseOrderItem.purchaseOrderItemNumber,
    materialDescription: record.purchaseOrderItem.materialDescription,
    materialNumber: record.purchaseOrderItem.materialNumber,
    suppliedQty: record.quantity,
    countryOfOrigin: record.countryOfOrigin,
  }))

  const packageAttachments = selectedPackage?.packageAttachments
  const purchaseOrderItemAtatchments = selectedPackage?.purchaseOrderItemSupplies?.map((record) => ({
    purchaseOrderItemNumber: record.purchaseOrderItem.purchaseOrderItemNumber,
    documentName: record.purchaseOrderItemSupplyAttachments?.map((attachment) => attachment.documentName.replaceAll("_", " ")).join(", "),
  })) 
  const generatePdf = () => {
    const pdfContent = document.getElementById('pdf-content');

    html3pdf(pdfContent, {
      margin: 10,
      filename: `${proofOfCollection?.pocNumber}-${currentDatetime.substring(0, 10)}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html3canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    });
  };

  return (
    <div style={{padding: "16px 0px"}}>
      {/* Button to generate PDF */}
      <div style={{display: "flex",justifyContent: "end", margin: "0 16px"}}>
        <div className='actions blue' style={{width: "fit-content"}} onClick={generatePdf}>
          <span className="material-symbols-rounded">download</span>
          <span>Download PDF</span>
        </div>
      </div>
      <hr style={{border: ".4px solid #e9e9e9", marginTop: "16px"}} />

      {/*  div with content for PDF generation */}
      <div id="pdf-content" className='pdf-content' style={{padding: "0 24px"}}>
        <div className='mb-2'>
          <p style={{margin: "16px 0 6px 0"}} className='fw-600'>{proofOfCollection?.freightForwarderCompanyName}</p>
          <p style={{width: "200px", margin: "0"}}>{proofOfCollection?.freightForwarderAddress}</p>            
        </div>
        <h4 className='text-center' style={{color: "#002d7c"}}>PROOF OF COLLECTION</h4>

        <div className='d-flex mt-2 mb-2' style={{alignItems: "start"}}>
          <div>
            <p className='mt-1'>Purchase Order Number: <span className='fw-600 text-blue'>{ purchaseOrderItems ? purchaseOrderItems[0].purchaseOrderNumber : "" }</span></p>
            <p className='mt-1'>Material Readiness Document Number: <span className='fw-600'>{proofOfCollection?.materialReadinessDocuments ? proofOfCollection?.materialReadinessDocuments[0]?.mrdNumber : null}</span></p>
            <p className='mt-1'>Pick Up Date: <span className='fw-600'>{formatDateTime(proofOfCollection?.pickUpDate).substring(0, 10)}</span></p>
            <p>Created Date: <span className='fw-600'>{formatDateTime(proofOfCollection?.createdDate)}</span></p>
          </div>

          <div style={{textAlign: "right"}}>
          <p className='mt-1'>Freight Forwarder Reference: <span className='fw-600'>{ proofOfCollection?.freightForwarderReference?.toUpperCase() }</span></p>
            <p>Client: <span className='fw-600'>{ proofOfCollection?.clientName }</span></p>
            <p>Country of Supply: <span className='fw-600'>{ proofOfCollection.materialReadinessDocuments ? proofOfCollection.materialReadinessDocuments[0].countryOfSupply : null }</span></p>
          </div>
        </div>
        
        <table className='custom-table custom'>
          <thead>
            <tr>
              <th className='text-center'>Package</th>              
              <th className='text-center'>Dimensions (L x W x H)</th>
              <th className='text-center'>Cubic Meter</th>
              <th className='text-center'>Weight</th>
              {proofOfCollection?.supplier && <th className='text-center'>Vendor</th>}
              <th className='text-center'>Destination</th>
              
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='text-center'>Package 1</td>
              <td className='text-center'>{ `${selectedPackage?.length} x ${selectedPackage?.width} x ${selectedPackage?.height}` }</td>
              <td className='text-center'>{ selectedPackage?.cubicMeter }</td>
              <td className='text-center'>{ selectedPackage?.grossWeight }</td>
              {proofOfCollection?.supplier && <td className='text-center'>{ proofOfCollection?.supplier?.companyName }</td>}
              <td className='text-center'>{ selectedPackage?.destination }</td>
            </tr>           
          </tbody>

        </table>

        <table className='custom-table custom mt-2'style={{marginBottom:"24px"}} >
          <thead>
            <tr>
              <th className='text-center'>SN</th>
              <th className='text-center'>PO Item N<sup>0</sup></th>
              <th className='text-center'>Material Number</th>
              <th colSpan={2}>Material Description</th>
              <th className='text-center'>Quantity</th>
              <th className='text-center'>Country of Origin</th>
            </tr>
          </thead>
          <tbody>
            {purchaseOrderItems?.sort((a, b) => a.purchaseOrderItemNumber - b.purchaseOrderItemNumber).map((data, index) => (
              <tr key={index}>
                <td className='text-center'>{ index + 1 }</td>
                <td className='text-center'>Item { data.purchaseOrderItemNumber }</td>
                <td className='text-center'>{ data.materialNumber }</td>
                <td colSpan={2}>{ data.materialDescription }</td>
                <td className='text-center'>{ data.suppliedQty }</td>
                <td className='text-center'>{ data.countryOfOrigin }</td>
              </tr>
            ))}            
          </tbody>
        </table>  

        <div className='d-flex' style={{alignItems: "start"}}>
        <div style={{flex:"1 100%"}}>
          {
            packageAttachments?.map((attachment, index) => {
              return (
                <p key={index} className='d-flex-2'>
                  <span className='material-symbols-rounded text-green mr-2'>check</span>
                  <span>{attachment.documentName.replaceAll("_", " ")} ({formatDateTime(attachment.createdDate)})</span>
                </p>
              )
          })} 
        </div>
        <div style={{flex:"1 100%"}}>
          {
            purchaseOrderItemAtatchments?.map((attachment, index) => {
              return (
                <p key={index} className='d-flex-2'>
                  <span className='material-symbols-rounded text-green mr-2'>check</span>
                  <span>Item {attachment.purchaseOrderItemNumber}: {attachment.documentName}</span>
                </p>
              )
          })} 
        </div>
        </div>     

        {proofOfCollection?.supplierApproval === "APPROVED" && 
        <div className='mt-2'>
          <p>ACKNOWLEDGED BY:</p>
          <p className='fw-600 blue-text'>SUPPLIER ({proofOfCollection?.supplier.companyName})</p>
          {proofOfCollection?.transitOfficerAcknowledgement === "ACKNOWLEDGED" && <p className='fw-600 blue-text mt-1'>TRANSIT TEAM</p>}
        </div>
        } 
      </div>
    </div>
  );
};

export default PdfGenerator;

import { useState, useEffect, FormEvent } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useLocation, useParams } from "react-router-dom";
import loading from "../../../assets/images/loading.gif"
import Modal from "react-modal"
import PdfGeneratorConsCommercialInvoice from "../../pdftemplates/generateConsolidatedCommercialInvoice";
import PdfGeneratorConsPackingList from "../../pdftemplates/generateConsolidatedPackingList";
import { makeGetRequest, makePatchRequest, makePostRequest } from "../../../request";
import { useSelector } from "react-redux";
import Layout from "../../Layout";
import { ClearingProcessStatus, customStyles, formatDateTime, handleDownloadForShippingDocuments, handlePreviewForShippingDocuments } from "helpers";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const ShippingDocumentsDetail =  () => {
    const accessToken:any = useSelector((state: any) => state.accessToken.value);
    const param = useParams()
    const location = useLocation()
    const previousPageData = location.state as { status: number };
    const roles:any = useSelector((state: any) => state.roles.value);
    const user:any = useSelector((state: any) => state.tepngUser.value);
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] =useState<Record <string, any>>([])
    const [isShowInvoice, setIsShowInvoice] = useState(false)
    const [isShowPackingList, setIsShowPackingList] = useState(false)

    const getShippingDocument = () => {
        setIsLoading(true)
        var request: Record<string, any> = {
            what: "getShippingDocumentById",
            id: param.id
        };
        
        makeGetRequest(request)
            .then((response: any) => {
                setIsLoading(false)
                const res = response.data.data
                setData(res)
            })
            .catch((error:any) => 
                toast.error(error.errorMessage)
            );
    }

    const handleDownloadClick = async (shipment: any, documentName: string, title: string) => {
        // Set isLoading to true when the download process starts
        setIsLoading(true);
    
        // Call the function to handle the document download
        const result = await handleDownloadForShippingDocuments(shipment, documentName, title);
    
        // Set isLoading to false based on the result of the download operation
        setIsLoading(result);
    };
    const handlePreviewClick = async (shipment: any, documentName: string) => {
        // Set isLoading to true when the download process starts
        setIsLoading(true);
    
        // Call the function to handle the document download
        const result = await handlePreviewForShippingDocuments(shipment, documentName);
    
        // Set isLoading to false based on the result of the download operation
        setIsLoading(result);
    }; 
    
    /**
     * Retrives the last occurence that matches the document name
     *
     * @param {any} shipment - The shipment object containing the document attachments.
     * @param {string} documentName - The name of the document to retrieve the blob storage name for.
     * @return {string | null} The document blob storage name if found, otherwise null.
     */
    const getDocumentBlobStorageName = (shipment: any, documentName: string) => {
        return shipment?.shippingDocumentAttachments?.reduce((prev: any, curr: any) => {
          return curr.documentName === documentName ? curr : prev;
        }, null)?.documentBlobStorageName;
    };

    const getDocumentUploadDate = (shipment:any, documentName: string) => {
        return shipment?.shippingDocumentAttachments?.find((attachment: any) => attachment.documentName === documentName)?.createdDate
    }

    const [formData, setFormData] = useState({
        documentName: "",
        document: "",
        filename: ""
    })

    const clearFormData = () => {
        setFormData({
            documentName: "",
            document: "",
            filename: ""
        })
        const fileinputElement = document.getElementById(formData.documentName) as HTMLInputElement
        if(fileinputElement) fileinputElement.value = ""
    }
    const handleFileChange = (event: any, documentName: string) => {
        const {name, files} = event.target
        let selectedFile = files[0]

        if(selectedFile) {
            let file = selectedFile.name.split(".");
            if (file[1] === "zip" || file[1] === "pdf") setFormData({...formData, [name]: selectedFile, filename: selectedFile.name, documentName: documentName}) 
            else {
                toast.error("Attempted to upload an invalid file format. Please re-upload the correct file formats.")
                const element = event.target as HTMLInputElement
                element.value = ""
            }  
        }             
    }

    const handleUpload = () => {
        const form = new FormData()        
        form.append("documentName", formData.documentName)
        form.append("document", formData.document)
        form.append("shippingDocumentId", data?.id)
                
        setIsSubmitting2(true)
        var request:Record<string, any> = {
            what: "",
            data: form
        };      
        if(formData.documentName === "PreArrivalAssessmentReport") request.what = "UploadPreArrivalAssessmentReport"
        else request.what = "UploadOtherShippingDocuments"

        makePostRequest(request)
            .then((response: any) => {  
                setIsSubmitting2(false)          
                toast.success(response.msg)
                getShippingDocument()   
                clearFormData()      
            })
            .catch((error:any) => {toast.error(error.msg); setIsSubmitting2(false)});
    }

    const [chats, setChats] = useState<Record <string, any>>([])
    const [isChatLoading, setIsChatLoading] = useState(false)
    const [openChatHistory, setOpenChatHistory] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isSubmitting2, setIsSubmitting2] = useState(false)
    const [chatData, setChatData] = useState({
        shippingDocumentId: "", 
        localClearingAgentId: "",
        receiver: "",
        comment: "",
    })
    const clearChatData = () => {
        setChatData({
            shippingDocumentId: "", 
            // freightForwarderId: "", 
            localClearingAgentId: "",
            receiver: "",
            comment: "",
        })
    }
    const handleSendChat = (e: FormEvent) => {
        e.preventDefault()
        setIsSubmitting(true)
        var request: Record<string, any> = {
            what: "AddCommentForShippingDocuments",
            data: {
                id: chatData.shippingDocumentId,
                comment: chatData.comment,
                sender: user.id,
                senderCompany: user?.companyName,
                senderRole: roles?.join(", "),
                receiver: "TransitOfficer",
            }
        };
        
        makePatchRequest(request)
            .then((response: any) => {
                setIsSubmitting(false)
                clearChatData()
                toast.success("Chat Sent Successfully!")
                setOpenChatHistory(false)
                getShippingDocument()
            })
            .catch((error:any) => 
                {toast.error(error); setIsSubmitting(false)}
            );
    }   

    const getShippingChatHistory = (shippingDocumentId: string, localClearingAgentId: string) => {
        setIsChatLoading(true)
        var request: Record<string, any> = {
            what: "getShippingDocumentChatHistory",
            params: {
                shippingDocumentId: shippingDocumentId,
                sender: localClearingAgentId,
                receiver: "TransitOfficer",
                orderBy: 1
            }
        };
        
        makeGetRequest(request)
            .then((response: any) => {
                setIsChatLoading(false)
                const res = response.data.data
                setChats(res.sort((a: any, b: any) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()))
            })
            .catch((error:any) => 
                {toast.error(error.msg); setIsChatLoading(false)}
            );
    }


    useEffect(() => {
        if(accessToken) getShippingDocument() // eslint-disable-next-line
    }, [accessToken])

    const page = "Shipping Documents"

    return (
        <Layout title={page}>
            <div className="container">
                <div className="main">
                    <h3 className="page_title">{  }</h3>     
                    
                    <div className="main-inner">                  
                        <div className="detail-top-section">
                            <div className="d-flex">
                                <Link to={"/localclearingagent/shippingdocuments"} state={{status: previousPageData?.status}} className="actions">
                                    <p><span className="material-symbols-rounded">arrow_back</span> Back to Shipping Documents</p>
                                </Link>
                                <button 
                                    className="actions"
                                    onClick={() => {
                                        setOpenChatHistory(true); 
                                        getShippingChatHistory(data.id, user?.id);
                                        setChatData({...chatData, shippingDocumentId: data.id, localClearingAgentId: data.localClearingAgentId})}}
                                    ><p><span className="material-symbols-outlined">forum</span>Send | View Chats</p>
                                </button>  
                            </div>
                            <div className="tab">
                                <div className="tab-item active">
                                    <span className="material-symbols-rounded">visibility</span>
                                    <p>Preview Shipping Documents</p>
                                </div>
                            </div>                      
                        </div>
                    </div>   

                    <div className="main-inner mt-1" style={{minHeight: "100vh", padding: "16px", boxSizing: "border-box"}}> 
                        {data?.localClearingAgentStatus === 2 && <div className="alert alert-warning" style={{margin: 0, padding: "8px", width: "auto"}}>
                            <span className="material-symbols-outlined mr-2" style={{color: "#e7882e"}}>info</span>
                            <p style={{margin: 0}}>Click on the <strong>Send | View Chats</strong> button to provide feedback on the issue resolution. This action will notify the transit team of the changes and update the status to "Open".</p>
                        </div>}
                        <div style={{padding: "12px", border: "1px solid #d9d9d9", borderRadius: "6px", marginTop: "8px"}}>
                            <Box>
                                <Stepper 
                                    activeStep={ data?.clearingProcessStatuses?.length > 0 ? data?.clearingProcessStatuses?.length + 1 : 1 } alternativeLabel>
                                    {ClearingProcessStatus().map((label: any, index: number) => (
                                        <Step key={index}>
                                            <StepLabel>
                                                {(data?.shippingDocumentAttachments?.length && label.value === 1) && <span className="text-grey">{formatDateTime(data.shippingDocumentAttachments[data.shippingDocumentAttachments.length - 1]?.createdDate)}</span>}
                                                {label.value > 1 && <span className="text-grey">{formatDateTime(data?.clearingProcessStatuses?.find((cps: any) => cps.status === label.name)?.createdDate)}</span>}
                                                <br/> 
                                                <span>{label.name}</span>
                                            </StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            </Box>
                        </div>                        
                        <div className="accordion mt-1">
                            <div className={`header d-flex ${isShowInvoice ? 'active' : ''}`} onClick={() => setIsShowInvoice(!isShowInvoice)}>
                                <div className="d-flex">
                                    <span className="material-symbols-rounded">{isShowInvoice ? "expand_more" : "chevron_right"}</span>
                                    <span>Consolidated Commercial Invoice</span>
                                </div>
                                <p className="small-text m-0">Created: {formatDateTime(data?.createdDate)}</p>
                            </div>
                            {isShowInvoice && <div className="body">
                                <PdfGeneratorConsCommercialInvoice key="1" data={data?.consolidatedCommercialInvoice} />
                            </div>}
                        </div>    

                        <div className="accordion mt-1">
                            <div className={`header d-flex ${isShowPackingList ? 'active' : ''}`} onClick={() => setIsShowPackingList(!isShowPackingList)}>
                                <div className="d-flex">
                                    <span className="material-symbols-rounded">{isShowPackingList ? "expand_more" : "chevron_right"}</span>
                                    <span>Consolidated Packing List</span>
                                </div>
                                <p className="small-text m-0">Created: {formatDateTime(data?.createdDate)}</p>
                            </div>
                            {isShowPackingList && <div className="body">
                                <PdfGeneratorConsPackingList key="2" data={data?.consolidatedPackingList} />
                            </div>}
                        </div>

                        <div className="d-flex file-input-container mt-1">
                            <span className={!getDocumentBlobStorageName(data, "DraftBillOfLadingOrAirWayBill") ? "disabled" : ""}>
                                Draft Bill Of Lading/Air Way Bill
                                <p className="small-text m-0 mt-1">Uploaded: {formatDateTime(getDocumentUploadDate(data, "DraftBillOfLadingOrAirWayBill"))}</p>
                            </span>  
                            <div className="d-flex">
                            {getDocumentBlobStorageName(data, "DraftBillOfLadingOrAirWayBill")?.split('.')[1] === "pdf"
                                    &&<button className='actions blue mr-1'
                                        onClick={() => handlePreviewClick(data, "DraftBillOfLadingOrAirWayBill")}>
                                            <span className="material-symbols-rounded">preview</span>
                                            <span>Preview</span>
                                    </button> } 
                                <button className='actions blue' 
                                    disabled={!getDocumentBlobStorageName(data, "DraftBillOfLadingOrAirWayBill")} 
                                    onClick={() => handleDownloadClick(data, "DraftBillOfLadingOrAirWayBill", "Draft_Bill_Of_Lading_Or_Air_Way_Bill_")}>
                                        <span className="material-symbols-rounded">download</span>
                                        <span>Download</span>
                                </button>
                            </div>
                        </div>

                        <div className="d-flex file-input-container mt-1">
                            <span className={!getDocumentBlobStorageName(data, "DraftCombinedCertificateValueOrigin") ? "disabled" : ""}>
                                Draft Combined Certificate Value Origin (CCVO)
                                <p className="small-text m-0 mt-1">Uploaded: {formatDateTime(getDocumentUploadDate(data, "DraftCombinedCertificateValueOrigin"))}</p>
                            </span>
                            <div className="d-flex">
                            {getDocumentBlobStorageName(data, "DraftCombinedCertificateValueOrigin")?.split('.')[1] === "pdf"
                                    &&<button className='actions blue mr-1'
                                        onClick={() => handlePreviewClick(data, "DraftCombinedCertificateValueOrigin")}>
                                            <span className="material-symbols-rounded">preview</span>
                                            <span>Preview</span>
                                    </button> }   
                            <button className='actions blue' 
                                disabled={!getDocumentBlobStorageName(data, "DraftCombinedCertificateValueOrigin")} 
                                onClick={() => handleDownloadClick(data, "DraftCombinedCertificateValueOrigin", "Draft_Combined_Certificate_Value_Origin_")}>
                                    <span className="material-symbols-rounded">download</span>
                                    <span>Download</span>
                            </button>
                            </div>
                        </div>

                        <div className="d-flex file-input-container mt-1">
                            <span className={!getDocumentBlobStorageName(data, "BillOfLadingOrAirWayBill") ? "disabled" : ""}>
                                Bill Of Lading/Air Way Bill
                                <p className="small-text m-0 mt-1">Uploaded: {formatDateTime(getDocumentUploadDate(data, "BillOfLadingOrAirWayBill"))}</p>
                            </span> 

                            <div className="d-flex">
                            {getDocumentBlobStorageName(data, "BillOfLadingOrAirWayBill")?.split('.')[1] === "pdf"
                                    &&<button className='actions blue mr-1'
                                        onClick={() => handlePreviewClick(data, "BillOfLadingOrAirWayBill")}>
                                            <span className="material-symbols-rounded">preview</span>
                                            <span>Preview</span>
                                    </button> }  
                            <button className='actions blue' 
                                disabled={!getDocumentBlobStorageName(data, "BillOfLadingOrAirWayBill")} 
                                onClick={() => handleDownloadClick(data, "BillOfLadingOrAirWayBill", "Bill_Of_Lading_Or_Air_Way_Bill_")}>
                                    <span className="material-symbols-rounded">download</span>
                                    <span>Download</span>
                            </button>
                            </div>
                        </div>    

                        <div className="d-flex file-input-container mt-1">
                            <span className={!getDocumentBlobStorageName(data, "CombinedCertificateValueOrigin") ? "disabled" : ""}>
                                Combined Certificate Value Origin (CCVO)
                                <p className="small-text m-0 mt-1">Uploaded: {formatDateTime(getDocumentUploadDate(data, "CombinedCertificateValueOrigin"))}</p>
                            </span>  
                            <div className="d-flex">
                            {getDocumentBlobStorageName(data, "CombinedCertificateValueOrigin")?.split('.')[1] === "pdf"
                                    &&<button className='actions blue mr-1'
                                        onClick={() => handlePreviewClick(data, "CombinedCertificateValueOrigin")}>
                                            <span className="material-symbols-rounded">preview</span>
                                            <span>Preview</span>
                                    </button> } 
                                <button className='actions blue'
                                    disabled={!getDocumentBlobStorageName(data, "CombinedCertificateValueOrigin")} 
                                    onClick={() => handleDownloadClick(data, "CombinedCertificateValueOrigin", "Combined_Certificate_Value_Origin_")}>
                                        <span className="material-symbols-rounded">download</span>
                                        <span>Download</span>
                                </button>
                            </div>
                        </div> 

                        {/* Other shipping documents */}
                        <div className="file-input-container mt-1">
                            <div className="d-flex">
                                <span className={!getDocumentBlobStorageName(data, "FinalDutyAssessmentDocument") ? "disabled" : ""}>
                                    Final Duty Assessment Document
                                    <p className="small-text m-0 mt-1">Uploaded: {formatDateTime(getDocumentUploadDate(data, "FinalDutyAssessmentDocument"))}</p>
                                </span>  
                                <div className="d-flex">
                                    {getDocumentBlobStorageName(data, "FinalDutyAssessmentDocument")?.split('.')[1] === "pdf"
                                        &&<button className='actions blue mr-1'
                                            onClick={() => handlePreviewClick(data, "FinalDutyAssessmentDocument")}>
                                                <span className="material-symbols-rounded">preview</span>
                                                <span>Preview</span>
                                        </button> }
                                        <button className='actions blue'
                                            disabled={!getDocumentBlobStorageName(data, "FinalDutyAssessmentDocument")} 
                                            onClick={() => handleDownloadClick(data, "FinalDutyAssessmentDocument", "Final_Duty_Assessment_Document_")}>
                                                <span className="material-symbols-rounded">download</span>
                                                <span>Download</span>
                                        </button>
                                </div>
                            </div>
                            <input id="FinalDutyAssessmentDocument" name="document" type='file' accept='.zip, .pdf' onChange={(event) => handleFileChange(event, "FinalDutyAssessmentDocument")} required hidden />
                            {formData.documentName !== "FinalDutyAssessmentDocument" && <div 
                                className="d-flex-2 gap-2 actions mt-1" 
                                style={{justifyContent: "center", paddingTop: "8px", borderTop: "1px solid #d9d9d9"}}
                                onClick={() => document.getElementById("FinalDutyAssessmentDocument")?.click()}>
                                <span className="material-symbols-rounded">{!getDocumentBlobStorageName(data, "FinalDutyAssessmentDocument") ? "post_add" : "replay"}</span>
                                <span>{!getDocumentBlobStorageName(data, "FinalDutyAssessmentDocument") ? "Choose a File" : "Change File"}</span>                                
                            </div>}
                            {formData.documentName === "FinalDutyAssessmentDocument" && <div 
                                className="d-flex-2 mt-1" 
                                style={{justifyContent: "center", paddingTop: "8px", borderTop: "1px solid #d9d9d9", gap: "24px"}}
                                >
                                {!isSubmitting2 && <div className="d-flex-2 actions" onClick={() => handleUpload()}>
                                    <span className="material-symbols-rounded">upload</span>
                                    <span>Upload Document - {formData.filename}</span> 
                                </div>}    
                                {isSubmitting2 && <div className="text-grey">
                                    <span>Uploading...</span> 
                                </div>}  
             
                                {!isSubmitting2 && <div className="red-text d-flex-2 gap-2 text-red actions"
                                onClick={() => clearFormData()}                                
                                ><span className="material-symbols-rounded" style={{fontSize: "14px"}}>close</span>Remove</div>}                        
                            </div>}
                        </div>

                        <div className="file-input-container mt-1">
                            <div className="d-flex">
                                <span className={!getDocumentBlobStorageName(data, "StandardOrganisationOfNigeriaConformityAssessmentProgramme") ? "disabled" : ""}>
                                    SONCAP
                                    <p className="small-text m-0 mt-1">Uploaded: {formatDateTime(getDocumentUploadDate(data, "StandardOrganisationOfNigeriaConformityAssessmentProgramme"))}</p>
                                </span>  
                                <div className="d-flex">
                                    {getDocumentBlobStorageName(data, "StandardOrganisationOfNigeriaConformityAssessmentProgramme")?.split('.')[1] === "pdf"
                                        &&<button className='actions blue mr-1'
                                            onClick={() => handlePreviewClick(data, "StandardOrganisationOfNigeriaConformityAssessmentProgramme")}>
                                                <span className="material-symbols-rounded">preview</span>
                                                <span>Preview</span>
                                        </button> }
                                        <button className='actions blue'
                                            disabled={!getDocumentBlobStorageName(data, "StandardOrganisationOfNigeriaConformityAssessmentProgramme")} 
                                            onClick={() => handleDownloadClick(data, "StandardOrganisationOfNigeriaConformityAssessmentProgramme", "Standard_Organisation_Of_Nigeria_Conformity_Assessment_Programme_")}>
                                                <span className="material-symbols-rounded">download</span>
                                                <span>Download</span>
                                        </button>
                                </div>
                            </div>
                            <input id="StandardOrganisationOfNigeriaConformityAssessmentProgramme" name="document" type='file' accept='.zip, .pdf' onChange={(event) => handleFileChange(event, "StandardOrganisationOfNigeriaConformityAssessmentProgramme")} required hidden />
                            {formData.documentName !== "StandardOrganisationOfNigeriaConformityAssessmentProgramme" && <div 
                                className="d-flex-2 gap-2 actions mt-1" 
                                style={{justifyContent: "center", paddingTop: "8px", borderTop: "1px solid #d9d9d9"}}
                                onClick={() => document.getElementById("StandardOrganisationOfNigeriaConformityAssessmentProgramme")?.click()}>
                                <span className="material-symbols-rounded">{!getDocumentBlobStorageName(data, "StandardOrganisationOfNigeriaConformityAssessmentProgramme") ? "post_add" : "replay"}</span>
                                <span>{!getDocumentBlobStorageName(data, "StandardOrganisationOfNigeriaConformityAssessmentProgramme") ? "Choose a File" : "Change File"}</span>                                
                            </div>}
                            {formData.documentName === "StandardOrganisationOfNigeriaConformityAssessmentProgramme" && <div 
                                className="d-flex-2 mt-1" 
                                style={{justifyContent: "center", paddingTop: "8px", borderTop: "1px solid #d9d9d9", gap: "24px"}}
                                >
                                {!isSubmitting2 && <div className="d-flex-2 actions" onClick={() => handleUpload()}>
                                    <span className="material-symbols-rounded">upload</span>
                                    <span>Upload Document - {formData.filename}</span> 
                                </div>}    
                                {isSubmitting2 && <div className="text-grey">
                                    <span>Uploading...</span> 
                                </div>}  
             
                                {!isSubmitting2 && <div className="red-text d-flex-2 gap-2 text-red actions"
                                onClick={() => clearFormData()}                                
                                ><span className="material-symbols-rounded" style={{fontSize: "14px"}}>close</span>Remove</div>}                        
                            </div>}
                        </div>                         

                        <div className="file-input-container mt-1">
                            <div className="d-flex">
                                <span className={!getDocumentBlobStorageName(data, "PreArrivalAssessmentReport") ? "disabled" : ""}>
                                    Pre Arrival Assessment Report (PAAR)
                                    <p className="small-text m-0 mt-1">Uploaded: {formatDateTime(getDocumentUploadDate(data, "PreArrivalAssessmentReport"))}</p>
                                </span> 
                                <div className="d-flex">
                                    {getDocumentBlobStorageName(data, "PreArrivalAssessmentReport")?.split('.')[1] === "pdf"
                                    &&<button className='actions blue mr-1'
                                        onClick={() => handlePreviewClick(data, "PreArrivalAssessmentReport")}>
                                            <span className="material-symbols-rounded">preview</span>
                                            <span>Preview</span>
                                    </button> }
                                    <button className='actions blue'
                                        disabled={!getDocumentBlobStorageName(data, "PreArrivalAssessmentReport")} 
                                        onClick={() => handleDownloadClick(data, "PreArrivalAssessmentReport", "Pre_Arrival_Assessment_Report")}>
                                            <span className="material-symbols-rounded">download</span>
                                            <span>Download</span>
                                    </button>
                                </div>
                            </div>
                            <input id="PreArrivalAssessmentReport" name="document" type='file' accept='.zip, .pdf' onChange={(event) => handleFileChange(event, "PreArrivalAssessmentReport")} required hidden />
                            {formData.documentName !== "PreArrivalAssessmentReport" && <div 
                                className="d-flex-2 gap-2 actions mt-1" 
                                style={{justifyContent: "center", paddingTop: "8px", borderTop: "1px solid #d9d9d9"}}
                                onClick={() => document.getElementById("PreArrivalAssessmentReport")?.click()}>
                                <span className="material-symbols-rounded">{!getDocumentBlobStorageName(data, "PreArrivalAssessmentReport") ? "post_add" : "replay"}</span>
                                <span>{!getDocumentBlobStorageName(data, "PreArrivalAssessmentReport") ? "Choose a File" : "Change File"}</span>                                
                            </div>}
                            {formData.documentName === "PreArrivalAssessmentReport" && <div 
                                className="d-flex-2 mt-1" 
                                style={{justifyContent: "center", paddingTop: "8px", borderTop: "1px solid #d9d9d9", gap: "24px"}}
                                >
                                {!isSubmitting2 && <div className="d-flex-2 actions" onClick={() => handleUpload()}>
                                    <span className="material-symbols-rounded">upload</span>
                                    <span>Upload Document - {formData.filename}</span> 
                                </div>}    
                                {isSubmitting2 && <div className="text-grey">
                                    <span>Uploading...</span> 
                                </div>}  
             
                                {!isSubmitting2 && <div className="red-text d-flex-2 gap-2 text-red actions"
                                onClick={() => clearFormData()}                                
                                ><span className="material-symbols-rounded" style={{fontSize: "14px"}}>close</span>Remove</div>}                        
                            </div>}
                        </div> 

                        <div className="file-input-container mt-1">
                            <div className="d-flex">
                                <span className={!getDocumentBlobStorageName(data, "DemandNotice") ? "disabled" : ""}>
                                    Demand Notice
                                    <p className="small-text m-0 mt-1">Uploaded: {formatDateTime(getDocumentUploadDate(data, "DemandNotice"))}</p>
                                </span>  
                                <div className="d-flex">
                                    {getDocumentBlobStorageName(data, "DemandNotice")?.split('.')[1] === "pdf"
                                    &&<button className='actions blue mr-1'
                                        onClick={() => handlePreviewClick(data, "DemandNotice")}>
                                            <span className="material-symbols-rounded">preview</span>
                                            <span>Preview</span>
                                    </button> }
                                    <button className='actions blue'
                                        disabled={!getDocumentBlobStorageName(data, "DemandNotice")} 
                                        onClick={() => handleDownloadClick(data, "DemandNotice", "Demand_Note_")}>
                                            <span className="material-symbols-rounded">download</span>
                                            <span>Download</span>
                                    </button>
                                </div>   
                            </div>
                            <input id="DemandNotice" name="document" type='file' accept='.zip, .pdf' onChange={(event) => handleFileChange(event, "DemandNotice")} required hidden />
                            {formData.documentName !== "DemandNotice" && <div 
                                className="d-flex-2 gap-2 actions mt-1" 
                                style={{justifyContent: "center", paddingTop: "8px", borderTop: "1px solid #d9d9d9"}}
                                onClick={() => document.getElementById("DemandNotice")?.click()}>
                                <span className="material-symbols-rounded">{!getDocumentBlobStorageName(data, "DemandNotice") ? "post_add" : "replay"}</span>
                                <span>{!getDocumentBlobStorageName(data, "DemandNotice") ? "Choose a File" : "Change File"}</span>                                
                            </div>}
                            {formData.documentName === "DemandNotice" && <div 
                                className="d-flex-2 mt-1" 
                                style={{justifyContent: "center", paddingTop: "8px", borderTop: "1px solid #d9d9d9", gap: "24px"}}
                                >
                                {!isSubmitting2 && <div className="d-flex-2 actions" onClick={() => handleUpload()}>
                                    <span className="material-symbols-rounded">upload</span>
                                    <span>Upload Document - {formData.filename}</span> 
                                </div>}    
                                {isSubmitting2 && <div className="text-grey">
                                    <span>Uploading...</span> 
                                </div>}  
             
                                {!isSubmitting2 && <div className="red-text d-flex-2 gap-2 text-red actions"
                                onClick={() => clearFormData()}                                
                                ><span className="material-symbols-rounded" style={{fontSize: "14px"}}>close</span>Remove</div>}                        
                            </div>}
                        </div>

                        <div className="file-input-container mt-1">
                            <div className="d-flex">
                                <span className={!getDocumentBlobStorageName(data, "DemandNoticePaymentReceipt") ? "disabled" : ""}>
                                    Demand Notice Payment Receipt
                                    <p className="small-text m-0 mt-1">Uploaded: {formatDateTime(getDocumentUploadDate(data, "DemandNoticePaymentReceipt"))}</p>
                                </span>  
                                <div className="d-flex">
                                    {getDocumentBlobStorageName(data, "DemandNoticePaymentReceipt")?.split('.')[1] === "pdf"
                                        &&<button className='actions blue mr-1'
                                            onClick={() => handlePreviewClick(data, "DemandNoticePaymentReceipt")}>
                                                <span className="material-symbols-rounded">preview</span>
                                                <span>Preview</span>
                                        </button> }
                                        <button className='actions blue'
                                            disabled={!getDocumentBlobStorageName(data, "DemandNoticePaymentReceipt")} 
                                            onClick={() => handleDownloadClick(data, "DemandNoticePaymentReceipt", "Demand_Notice_Payment_Receipt_")}>
                                                <span className="material-symbols-rounded">download</span>
                                                <span>Download</span>
                                        </button>
                                </div>
                            </div>
                            <input id="DemandNoticePaymentReceipt" name="document" type='file' accept='.zip, .pdf' onChange={(event) => handleFileChange(event, "DemandNoticePaymentReceipt")} required hidden />
                            {formData.documentName !== "DemandNoticePaymentReceipt" && <div 
                                className="d-flex-2 gap-2 actions mt-1" 
                                style={{justifyContent: "center", paddingTop: "8px", borderTop: "1px solid #d9d9d9"}}
                                onClick={() => {document.getElementById("DemandNoticePaymentReceipt")?.click(); clearFormData()}}>
                                <span className="material-symbols-rounded">{!getDocumentBlobStorageName(data, "DemandNoticePaymentReceipt") ? "post_add" : "replay"}</span>
                                <span>{!getDocumentBlobStorageName(data, "DemandNoticePaymentReceipt") ? "Choose a File" : "Change File"}</span>                                
                            </div>}
                            {formData.documentName === "DemandNoticePaymentReceipt" && <div 
                                className="d-flex-2 mt-1" 
                                style={{justifyContent: "center", paddingTop: "8px", borderTop: "1px solid #d9d9d9", gap: "24px"}}
                                >
                                {!isSubmitting2 && <div className="d-flex-2 actions" onClick={() => handleUpload()}>
                                    <span className="material-symbols-rounded">upload</span>
                                    <span>Upload Document - {formData.filename}</span> 
                                </div>}    
                                {isSubmitting2 && <div className="text-grey">
                                    <span>Uploading...</span> 
                                </div>}  
             
                                {!isSubmitting2 && <div className="red-text d-flex-2 gap-2 text-red actions"
                                onClick={() => clearFormData()}                                
                                ><span className="material-symbols-rounded" style={{fontSize: "14px"}}>close</span>Remove</div>}                        
                            </div>}
                        </div> 

                        <div className="file-input-container mt-1">
                            <div className="d-flex">
                                <span className={!getDocumentBlobStorageName(data, "SingleGoodsDeclarationForm") ? "disabled" : ""}>
                                    SGD Form
                                    <p className="small-text m-0 mt-1">Uploaded: {formatDateTime(getDocumentUploadDate(data, "SingleGoodsDeclarationForm"))}</p>
                                </span>  
                                <div className="d-flex">
                                    {getDocumentBlobStorageName(data, "SingleGoodsDeclarationForm")?.split('.')[1] === "pdf"
                                        &&<button className='actions blue mr-1'
                                            onClick={() => handlePreviewClick(data, "SingleGoodsDeclarationForm")}>
                                                <span className="material-symbols-rounded">preview</span>
                                                <span>Preview</span>
                                        </button> }
                                        <button className='actions blue'
                                            disabled={!getDocumentBlobStorageName(data, "SingleGoodsDeclarationForm")} 
                                            onClick={() => handleDownloadClick(data, "SingleGoodsDeclarationForm", "Single_Goods_Declaration_Form_")}>
                                                <span className="material-symbols-rounded">download</span>
                                                <span>Download</span>
                                        </button>
                                </div>
                            </div>
                            <input id="SingleGoodsDeclarationForm" name="document" type='file' accept='.zip, .pdf' onChange={(event) => handleFileChange(event, "SingleGoodsDeclarationForm")} required hidden />
                            {formData.documentName !== "SingleGoodsDeclarationForm" && <div 
                                className="d-flex-2 gap-2 actions mt-1" 
                                style={{justifyContent: "center", paddingTop: "8px", borderTop: "1px solid #d9d9d9"}}
                                onClick={() => document.getElementById("SingleGoodsDeclarationForm")?.click()}>
                                <span className="material-symbols-rounded">{!getDocumentBlobStorageName(data, "SingleGoodsDeclarationForm") ? "post_add" : "replay"}</span>
                                <span>{!getDocumentBlobStorageName(data, "SingleGoodsDeclarationForm") ? "Choose a File" : "Change File"}</span>                                
                            </div>}
                            {formData.documentName === "SingleGoodsDeclarationForm" && <div 
                                className="d-flex-2 mt-1" 
                                style={{justifyContent: "center", paddingTop: "8px", borderTop: "1px solid #d9d9d9", gap: "24px"}}
                                >
                                {!isSubmitting2 && <div className="d-flex-2 actions" onClick={() => handleUpload()}>
                                    <span className="material-symbols-rounded">upload</span>
                                    <span>Upload Document - {formData.filename}</span> 
                                </div>}    
                                {isSubmitting2 && <div className="text-grey">
                                    <span>Uploading...</span> 
                                </div>}  
             
                                {!isSubmitting2 && <div className="red-text d-flex-2 gap-2 text-red actions"
                                onClick={() => clearFormData()}                                
                                ><span className="material-symbols-rounded" style={{fontSize: "14px"}}>close</span>Remove</div>}                        
                            </div>}
                        </div> 

                        <div className="file-input-container mt-1">
                            <div className="d-flex">
                                <span className={!getDocumentBlobStorageName(data, "SingleGoodsDeclarationDetailOfValuationNote") ? "disabled" : ""}>
                                    SGD Detail Of Valuation Note
                                    <p className="small-text m-0 mt-1">Uploaded: {formatDateTime(getDocumentUploadDate(data, "SingleGoodsDeclarationDetailOfValuationNote"))}</p>
                                </span>  
                                <div className="d-flex">
                                    {getDocumentBlobStorageName(data, "SingleGoodsDeclarationDetailOfValuationNote")?.split('.')[1] === "pdf"
                                        &&<button className='actions blue mr-1'
                                            onClick={() => handlePreviewClick(data, "SingleGoodsDeclarationDetailOfValuationNote")}>
                                                <span className="material-symbols-rounded">preview</span>
                                                <span>Preview</span>
                                        </button> }
                                        <button className='actions blue'
                                            disabled={!getDocumentBlobStorageName(data, "SingleGoodsDeclarationDetailOfValuationNote")} 
                                            onClick={() => handleDownloadClick(data, "SingleGoodsDeclarationDetailOfValuationNote", "Single_Goods_Declaration_Detail_Of_Valuation_Note_")}>
                                                <span className="material-symbols-rounded">download</span>
                                                <span>Download</span>
                                        </button>
                                </div>
                            </div>
                            <input id="SingleGoodsDeclarationDetailOfValuationNote" name="document" type='file' accept='.zip, .pdf' onChange={(event) => handleFileChange(event, "SingleGoodsDeclarationDetailOfValuationNote")} required hidden />
                            {formData.documentName !== "SingleGoodsDeclarationDetailOfValuationNote" && <div 
                                className="d-flex-2 gap-2 actions mt-1" 
                                style={{justifyContent: "center", paddingTop: "8px", borderTop: "1px solid #d9d9d9"}}
                                onClick={() => document.getElementById("SingleGoodsDeclarationDetailOfValuationNote")?.click()}>
                                <span className="material-symbols-rounded">{!getDocumentBlobStorageName(data, "SingleGoodsDeclarationDetailOfValuationNote") ? "post_add" : "replay"}</span>
                                <span>{!getDocumentBlobStorageName(data, "SingleGoodsDeclarationDetailOfValuationNote") ? "Choose a File" : "Change File"}</span>                                
                            </div>}
                            {formData.documentName === "SingleGoodsDeclarationDetailOfValuationNote" && <div 
                                className="d-flex-2 mt-1" 
                                style={{justifyContent: "center", paddingTop: "8px", borderTop: "1px solid #d9d9d9", gap: "24px"}}
                                >
                                {!isSubmitting2 && <div className="d-flex-2 actions" onClick={() => handleUpload()}>
                                    <span className="material-symbols-rounded">upload</span>
                                    <span>Upload Document - {formData.filename}</span> 
                                </div>}    
                                {isSubmitting2 && <div className="text-grey">
                                    <span>Uploading...</span> 
                                </div>}  
             
                                {!isSubmitting2 && <div className="red-text d-flex-2 gap-2 text-red actions"
                                onClick={() => clearFormData()}                                
                                ><span className="material-symbols-rounded" style={{fontSize: "14px"}}>close</span>Remove</div>}                        
                            </div>}
                        </div> 

                        <div className="file-input-container mt-1">
                            <div className="d-flex">
                                <span className={!getDocumentBlobStorageName(data, "ExchangeControl") ? "disabled" : ""}>
                                    Exchange Control
                                    <p className="small-text m-0 mt-1">Uploaded: {formatDateTime(getDocumentUploadDate(data, "ExchangeControl"))}</p>
                                </span>  
                                <div className="d-flex">
                                    {getDocumentBlobStorageName(data, "ExchangeControl")?.split('.')[1] === "pdf"
                                        &&<button className='actions blue mr-1'
                                            onClick={() => handlePreviewClick(data, "ExchangeControl")}>
                                                <span className="material-symbols-rounded">preview</span>
                                                <span>Preview</span>
                                        </button> }
                                        <button className='actions blue'
                                            disabled={!getDocumentBlobStorageName(data, "ExchangeControl")} 
                                            onClick={() => handleDownloadClick(data, "ExchangeControl", "Exchange_Control_")}>
                                                <span className="material-symbols-rounded">download</span>
                                                <span>Download</span>
                                        </button>
                                </div>
                            </div>
                            <input id="ExchangeControl" name="document" type='file' accept='.zip, .pdf' onChange={(event) => handleFileChange(event, "ExchangeControl")} required hidden />
                            {formData.documentName !== "ExchangeControl" && <div 
                                className="d-flex-2 gap-2 actions mt-1" 
                                style={{justifyContent: "center", paddingTop: "8px", borderTop: "1px solid #d9d9d9"}}
                                onClick={() => document.getElementById("ExchangeControl")?.click()}>
                            <span className="material-symbols-rounded">{!getDocumentBlobStorageName(data, "ExchangeControl") ? "post_add" : "replay"}</span>
                                <span>{!getDocumentBlobStorageName(data, "ExchangeControl") ? "Choose a File" : "Change File"}</span>                                
                            </div>}
                            {formData.documentName === "ExchangeControl" && <div 
                                className="d-flex-2 mt-1" 
                                style={{justifyContent: "center", paddingTop: "8px", borderTop: "1px solid #d9d9d9", gap: "24px"}}
                                >
                                {!isSubmitting2 && <div className="d-flex-2 actions" onClick={() => handleUpload()}>
                                    <span className="material-symbols-rounded">upload</span>
                                    <span>Upload Document - {formData.filename}</span> 
                                </div>}    
                                {isSubmitting2 && <div className="text-grey">
                                    <span>Uploading...</span> 
                                </div>}  
             
                                {!isSubmitting2 && <div className="red-text d-flex-2 gap-2 text-red actions"
                                onClick={() => clearFormData()}                                
                                ><span className="material-symbols-rounded" style={{fontSize: "14px"}}>close</span>Remove</div>}                        
                            </div>}
                        </div> 

                        <div className="file-input-container mt-1">
                            <div className="d-flex">
                                <span className={!getDocumentBlobStorageName(data, "FormM") ? "disabled" : ""}>
                                    Form M
                                    <p className="small-text m-0 mt-1">Uploaded: {formatDateTime(getDocumentUploadDate(data, "FormM"))}</p>
                                </span>  
                                <div className="d-flex">
                                    {getDocumentBlobStorageName(data, "FormM")?.split('.')[1] === "pdf"
                                        &&<button className='actions blue mr-1'
                                            onClick={() => handlePreviewClick(data, "FormM")}>
                                                <span className="material-symbols-rounded">preview</span>
                                                <span>Preview</span>
                                        </button> }
                                        <button className='actions blue'
                                            disabled={!getDocumentBlobStorageName(data, "FormM")} 
                                            onClick={() => handleDownloadClick(data, "FormM", "FormM_")}>
                                                <span className="material-symbols-rounded">download</span>
                                                <span>Download</span>
                                        </button>
                                </div>
                            </div>
                            <input id="FormM" name="document" type='file' accept='.zip, .pdf' onChange={(event) => handleFileChange(event, "FormM")} required hidden />
                            {formData.documentName !== "FormM" && <div 
                                className="d-flex-2 gap-2 actions mt-1" 
                                style={{justifyContent: "center", paddingTop: "8px", borderTop: "1px solid #d9d9d9"}}
                                onClick={() => document.getElementById("FormM")?.click()}>
                                <span className="material-symbols-rounded">{!getDocumentBlobStorageName(data, "FormM") ? "post_add" : "replay"}</span>
                                <span>{!getDocumentBlobStorageName(data, "FormM") ? "Choose a File" : "Change File"}</span>                                
                            </div>}
                            {formData.documentName === "FormM" && <div 
                                className="d-flex-2 mt-1" 
                                style={{justifyContent: "center", paddingTop: "8px", borderTop: "1px solid #d9d9d9", gap: "24px"}}
                                >
                                {!isSubmitting2 && <div className="d-flex-2 actions" onClick={() => handleUpload()}>
                                    <span className="material-symbols-rounded">upload</span>
                                    <span>Upload Document - {formData.filename}</span> 
                                </div>}    
                                {isSubmitting2 && <div className="text-grey">
                                    <span>Uploading...</span> 
                                </div>}  
             
                                {!isSubmitting2 && <div className="red-text d-flex-2 gap-2 text-red actions"
                                onClick={() => clearFormData()}                                
                                ><span className="material-symbols-rounded" style={{fontSize: "14px"}}>close</span>Remove</div>}                        
                            </div>}
                        </div>

                        <div className="file-input-container mt-1">
                            <div className="d-flex">
                                <span className={!getDocumentBlobStorageName(data, "ShippingAndTerminalCompanyReceipts") ? "disabled" : ""}>
                                    Shipping And Terminal Company Receipts
                                    <p className="small-text m-0 mt-1">Uploaded: {formatDateTime(getDocumentUploadDate(data, "ShippingAndTerminalCompanyReceipts"))}</p>
                                </span>  
                                <div className="d-flex">
                                    {getDocumentBlobStorageName(data, "ShippingAndTerminalCompanyReceipts")?.split('.')[1] === "pdf"
                                        &&<button className='actions blue mr-1'
                                            onClick={() => handlePreviewClick(data, "ShippingAndTerminalCompanyReceipts")}>
                                                <span className="material-symbols-rounded">preview</span>
                                                <span>Preview</span>
                                        </button> }
                                        <button className='actions blue'
                                            disabled={!getDocumentBlobStorageName(data, "ShippingAndTerminalCompanyReceipts")} 
                                            onClick={() => handleDownloadClick(data, "ShippingAndTerminalCompanyReceipts", "Shipping_And_Terminal_Company_Receipts_")}>
                                                <span className="material-symbols-rounded">download</span>
                                                <span>Download</span>
                                        </button>
                                </div>
                            </div>
                            <input id="ShippingAndTerminalCompanyReceipts" name="document" type='file' accept='.zip, .pdf' onChange={(event) => handleFileChange(event, "ShippingAndTerminalCompanyReceipts")} required hidden />
                            {formData.documentName !== "ShippingAndTerminalCompanyReceipts" && <div 
                                className="d-flex-2 gap-2 actions mt-1" 
                                style={{justifyContent: "center", paddingTop: "8px", borderTop: "1px solid #d9d9d9"}}
                                onClick={() => document.getElementById("ShippingAndTerminalCompanyReceipts")?.click()}>
                                <span className="material-symbols-rounded">{!getDocumentBlobStorageName(data, "ShippingAndTerminalCompanyReceipts") ? "post_add" : "replay"}</span>
                                <span>{!getDocumentBlobStorageName(data, "ShippingAndTerminalCompanyReceipts") ? "Choose a File" : "Change File"}</span>                                
                            </div>}
                            {formData.documentName === "ShippingAndTerminalCompanyReceipts" && <div 
                                className="d-flex-2 mt-1" 
                                style={{justifyContent: "center", paddingTop: "8px", borderTop: "1px solid #d9d9d9", gap: "24px"}}
                                >
                                {!isSubmitting2 && <div className="d-flex-2 actions" onClick={() => handleUpload()}>
                                    <span className="material-symbols-rounded">upload</span>
                                    <span>Upload Document - {formData.filename}</span> 
                                </div>}    
                                {isSubmitting2 && <div className="text-grey">
                                    <span>Uploading...</span> 
                                </div>}  
             
                                {!isSubmitting2 && <div className="red-text d-flex-2 gap-2 text-red actions"
                                onClick={() => clearFormData()}                                
                                ><span className="material-symbols-rounded" style={{fontSize: "14px"}}>close</span>Remove</div>}                        
                            </div>}
                        </div>

                        <div className="file-input-container mt-1">
                            <div className="d-flex">
                                <span className={!getDocumentBlobStorageName(data, "ProofOfDelivery") ? "disabled" : ""}>
                                    Proof Of Delivery
                                    <p className="small-text m-0 mt-1">Uploaded: {formatDateTime(getDocumentUploadDate(data, "ProofOfDelivery"))}</p>
                                </span>  
                                <div className="d-flex">
                                    {getDocumentBlobStorageName(data, "ProofOfDelivery")?.split('.')[1] === "pdf"
                                        &&<button className='actions blue mr-1'
                                            onClick={() => handlePreviewClick(data, "ProofOfDelivery")}>
                                                <span className="material-symbols-rounded">preview</span>
                                                <span>Preview</span>
                                        </button> }
                                        <button className='actions blue'
                                            disabled={!getDocumentBlobStorageName(data, "ProofOfDelivery")} 
                                            onClick={() => handleDownloadClick(data, "ProofOfDelivery", "Proof_Of_Delivery_")}>
                                                <span className="material-symbols-rounded">download</span>
                                                <span>Download</span>
                                        </button>
                                </div>
                            </div>
                            <input id="ProofOfDelivery" name="document" type='file' accept='.zip, .pdf' onChange={(event) => handleFileChange(event, "ProofOfDelivery")} required hidden />
                            {formData.documentName !== "ProofOfDelivery" && <div 
                                className="d-flex-2 gap-2 actions mt-1" 
                                style={{justifyContent: "center", paddingTop: "8px", borderTop: "1px solid #d9d9d9"}}
                                onClick={() => document.getElementById("ProofOfDelivery")?.click()}>
                                <span className="material-symbols-rounded">{!getDocumentBlobStorageName(data, "ProofOfDelivery") ? "post_add" : "replay"}</span>
                                <span>{!getDocumentBlobStorageName(data, "ProofOfDelivery") ? "Choose a File" : "Change File"}</span>                                
                            </div>}
                            {formData.documentName === "ProofOfDelivery" && <div 
                                className="d-flex-2 mt-1" 
                                style={{justifyContent: "center", paddingTop: "8px", borderTop: "1px solid #d9d9d9", gap: "24px"}}
                                >
                                {!isSubmitting2 && <div className="d-flex-2 actions" onClick={() => handleUpload()}>
                                    <span className="material-symbols-rounded">upload</span>
                                    <span>Upload Document - {formData.filename}</span> 
                                </div>}    
                                {isSubmitting2 && <div className="text-grey">
                                    <span>Uploading...</span> 
                                </div>}  
             
                                {!isSubmitting2 && <div className="red-text d-flex-2 gap-2 text-red actions"
                                onClick={() => clearFormData()}                                
                                ><span className="material-symbols-rounded" style={{fontSize: "14px"}}>close</span>Remove</div>}                        
                            </div>}
                        </div>

                        <div className="file-input-container mt-1">
                            <div className="d-flex">
                                <span className={!getDocumentBlobStorageName(data, "NAFDACReleaseNote") ? "disabled" : ""}>
                                    NAFDAC Release Note
                                    <p className="small-text m-0 mt-1">Uploaded: {formatDateTime(getDocumentUploadDate(data, "NAFDACReleaseNote"))}</p>
                                </span>  
                                <div className="d-flex">
                                    {getDocumentBlobStorageName(data, "NAFDACReleaseNote")?.split('.')[1] === "pdf"
                                        &&<button className='actions blue mr-1'
                                            onClick={() => handlePreviewClick(data, "NAFDACReleaseNote")}>
                                                <span className="material-symbols-rounded">preview</span>
                                                <span>Preview</span>
                                        </button> }
                                        <button className='actions blue'
                                            disabled={!getDocumentBlobStorageName(data, "NAFDACReleaseNote")} 
                                            onClick={() => handleDownloadClick(data, "NAFDACReleaseNote", "NAFDAC_Release_Note_")}>
                                                <span className="material-symbols-rounded">download</span>
                                                <span>Download</span>
                                        </button>
                                </div>
                            </div>
                            <input id="NAFDACReleaseNote" name="document" type='file' accept='.zip, .pdf' onChange={(event) => handleFileChange(event, "NAFDACReleaseNote")} required hidden />
                            {formData.documentName !== "NAFDACReleaseNote" && <div 
                                className="d-flex-2 gap-2 actions mt-1" 
                                style={{justifyContent: "center", paddingTop: "8px", borderTop: "1px solid #d9d9d9"}}
                                onClick={() => document.getElementById("NAFDACReleaseNote")?.click()}>
                                <span className="material-symbols-rounded">{!getDocumentBlobStorageName(data, "NAFDACReleaseNote") ? "post_add" : "replay"}</span>
                                <span>{!getDocumentBlobStorageName(data, "NAFDACReleaseNote") ? "Choose a File" : "Change File"}</span>                                
                            </div>}
                            {formData.documentName === "NAFDACReleaseNote" && <div 
                                className="d-flex-2 mt-1" 
                                style={{justifyContent: "center", paddingTop: "8px", borderTop: "1px solid #d9d9d9", gap: "24px"}}
                                >
                                {!isSubmitting2 && <div className="d-flex-2 actions" onClick={() => handleUpload()}>
                                    <span className="material-symbols-rounded">upload</span>
                                    <span>Upload Document - {formData.filename}</span> 
                                </div>}    
                                {isSubmitting2 && <div className="text-grey">
                                    <span>Uploading...</span> 
                                </div>}  
             
                                {!isSubmitting2 && <div className="red-text d-flex-2 gap-2 text-red actions"
                                onClick={() => clearFormData()}                                
                                ><span className="material-symbols-rounded" style={{fontSize: "14px"}}>close</span>Remove</div>}                        
                            </div>}
                        </div>

                        <div className="file-input-container mt-1">
                            <div className="d-flex">
                                <span className={!getDocumentBlobStorageName(data, "AcknowledgementLetter") ? "disabled" : ""}>
                                    Acknowledgement Letter
                                    <p className="small-text m-0 mt-1">Uploaded: {formatDateTime(getDocumentUploadDate(data, "AcknowledgementLetter"))}</p>
                                </span> 
                                <div className="d-flex">
                                    {getDocumentBlobStorageName(data, "AcknowledgementLetter")?.split('.')[1] === "pdf"
                                        &&<button className='actions blue mr-1'
                                            onClick={() => handlePreviewClick(data, "AcknowledgementLetter")}>
                                                <span className="material-symbols-rounded">preview</span>
                                                <span>Preview</span>
                                        </button> } 
                                        <button className='actions blue'
                                            disabled={!getDocumentBlobStorageName(data, "AcknowledgementLetter")} 
                                            onClick={() => handleDownloadClick(data, "AcknowledgementLetter", "Acknowledgement_Letter_")}>
                                                <span className="material-symbols-rounded">download</span>
                                                <span>Download</span>
                                        </button>
                                </div>
                            </div>
                            <input id="AcknowledgementLetter" name="document" type='file' accept='.zip, .pdf' onChange={(event) => handleFileChange(event, "AcknowledgementLetter")} required hidden/>
                            {formData.documentName !== "AcknowledgementLetter" && <div 
                                className="d-flex-2 gap-2 actions mt-1" 
                                style={{justifyContent: "center", paddingTop: "8px", borderTop: "1px solid #d9d9d9"}}
                                onClick={() => document.getElementById("AcknowledgementLetter")?.click()}>
                                <span className="material-symbols-rounded">{!getDocumentBlobStorageName(data, "AcknowledgementLetter") ? "post_add" : "replay"}</span>
                                <span>{!getDocumentBlobStorageName(data, "AcknowledgementLetter") ? "Choose a File" : "Change File"}</span>                                
                            </div>}
                            {formData.documentName === "AcknowledgementLetter" && <div 
                                className="d-flex-2 mt-1" 
                                style={{justifyContent: "center", paddingTop: "8px", borderTop: "1px solid #d9d9d9", gap: "24px"}}
                                >
                                {!isSubmitting2 && <div className="d-flex-2 actions" onClick={() => handleUpload()}>
                                    <span className="material-symbols-rounded">upload</span>
                                    <span>Upload Document - {formData.filename}</span> 
                                </div>}    
                                {isSubmitting2 && <div className="text-grey">
                                    <span>Uploading...</span> 
                                </div>}  
             
                                {!isSubmitting2 && <div className="red-text d-flex-2 gap-2 text-red actions"
                                onClick={() => clearFormData()}                                
                                ><span className="material-symbols-rounded" style={{fontSize: "14px"}}>close</span>Remove</div>}                        
                            </div>}
                        </div>

                        <div className="file-input-container mt-1">
                            <div className="d-flex">
                                <span className={!getDocumentBlobStorageName(data, "ExitNote") ? "disabled" : ""}>
                                    Customs Exit Note
                                    <p className="small-text m-0 mt-1">Uploaded: {formatDateTime(getDocumentUploadDate(data, "ExitNote"))}</p>
                                </span>  
                                <div className="d-flex">
                                    {getDocumentBlobStorageName(data, "ExitNote")?.split('.')[1] === "pdf"
                                        &&<button className='actions blue mr-1'
                                            onClick={() => handlePreviewClick(data, "ExitNote")}>
                                                <span className="material-symbols-rounded">preview</span>
                                                <span>Preview</span>
                                        </button> }
                                        <button className='actions blue'
                                            disabled={!getDocumentBlobStorageName(data, "ExitNote")} 
                                            onClick={() => handleDownloadClick(data, "ExitNote", "Customs_Exit_Note_")}>
                                                <span className="material-symbols-rounded">download</span>
                                                <span>Download</span>
                                        </button>
                                </div>
                            </div>
                            <input id="ExitNote" name="document" type='file' accept='.zip, .pdf' onChange={(event) => handleFileChange(event, "ExitNote")} required hidden/>
                            {formData.documentName !== "ExitNote" && <div 
                                className="d-flex-2 gap-2 actions mt-1" 
                                style={{justifyContent: "center", paddingTop: "8px", borderTop: "1px solid #d9d9d9"}}
                                onClick={() => document.getElementById("ExitNote")?.click()}>
                                <span className="material-symbols-rounded">{!getDocumentBlobStorageName(data, "ExitNote") ? "post_add" : "replay"}</span>
                                <span>{!getDocumentBlobStorageName(data, "ExitNote") ? "Choose a File" : "Change File"}</span>                                
                            </div>}
                            {formData.documentName === "ExitNote" && <div 
                                className="d-flex-2 mt-1" 
                                style={{justifyContent: "center", paddingTop: "8px", borderTop: "1px solid #d9d9d9", gap: "24px"}}
                                >
                                {!isSubmitting2 && <div className="d-flex-2 actions" onClick={() => handleUpload()}>
                                    <span className="material-symbols-rounded">upload</span>
                                    <span>Upload Document - {formData.filename}</span> 
                                </div>}    
                                {isSubmitting2 && <div className="text-grey">
                                    <span>Uploading...</span> 
                                </div>}  
             
                                {!isSubmitting2 && <div className="red-text d-flex-2 gap-2 text-red actions"
                                onClick={() => clearFormData()}                                
                                ><span className="material-symbols-rounded" style={{fontSize: "14px"}}>close</span>Remove</div>}                        
                            </div>}
                        </div>

                        <div className="file-input-container mt-1">
                            <div className="d-flex">
                                <span className={!getDocumentBlobStorageName(data, "ContainerRefundDocuments") ? "disabled" : ""}>
                                    Container Refund Documents
                                    <p className="small-text m-0 mt-1">Uploaded: {formatDateTime(getDocumentUploadDate(data, "ContainerRefundDocuments"))}</p>
                                </span> 
                                <div className="d-flex">
                                    {getDocumentBlobStorageName(data, "ContainerRefundDocuments")?.split('.')[1] === "pdf"
                                        &&<button className='actions blue mr-1'
                                            onClick={() => handlePreviewClick(data, "ContainerRefundDocuments")}>
                                                <span className="material-symbols-rounded">preview</span>
                                                <span>Preview</span>
                                        </button> } 
                                        <button className='actions blue'
                                            disabled={!getDocumentBlobStorageName(data, "ContainerRefundDocuments")} 
                                            onClick={() => handleDownloadClick(data, "ContainerRefundDocuments", "Container_Refund_Documents_")}>
                                                <span className="material-symbols-rounded">download</span>
                                                <span>Download</span>
                                        </button>
                                </div>
                            </div>
                            <input id="ContainerRefundDocuments" name="document" type='file' accept='.zip, .pdf' onChange={(event) => handleFileChange(event, "ContainerRefundDocuments")} required hidden/>
                            {formData.documentName !== "ContainerRefundDocuments" && <div 
                                className="d-flex-2 gap-2 actions mt-1" 
                                style={{justifyContent: "center", paddingTop: "8px", borderTop: "1px solid #d9d9d9"}}
                                onClick={() => document.getElementById("ContainerRefundDocuments")?.click()}>
                                <span className="material-symbols-rounded">{!getDocumentBlobStorageName(data, "ContainerRefundDocuments") ? "post_add" : "replay"}</span>
                                <span>{!getDocumentBlobStorageName(data, "ContainerRefundDocuments") ? "Choose a File" : "Change File"}</span>                                
                            </div>}
                            {formData.documentName === "ContainerRefundDocuments" && <div 
                                className="d-flex-2 mt-1" 
                                style={{justifyContent: "center", paddingTop: "8px", borderTop: "1px solid #d9d9d9", gap: "24px"}}
                                >
                                {!isSubmitting2 && <div className="d-flex-2 actions" onClick={() => handleUpload()}>
                                    <span className="material-symbols-rounded">upload</span>
                                    <span>Upload Document - {formData.filename}</span> 
                                </div>}    
                                {isSubmitting2 && <div className="text-grey">
                                    <span>Uploading...</span> 
                                </div>}  
             
                                {!isSubmitting2 && <div className="red-text d-flex-2 gap-2 text-red actions"
                                onClick={() => clearFormData()}                                
                                ><span className="material-symbols-rounded" style={{fontSize: "14px"}}>close</span>Remove</div>}                        
                            </div>}
                        </div>
                    </div>
                </div>
                <Modal isOpen={openChatHistory} style={customStyles} className="modal modal-7" ariaHideApp={false}>
                    <div className="modal-header">
                        <h3>Chats</h3>
                        <span className="material-symbols-rounded close"
                            onClick={() => {setOpenChatHistory(false); setIsSubmitting(false); clearChatData()} }>close</span>
                    </div>
                    <div className="modal-body" style={{ minHeight: "200px"}}>
                        {(!isChatLoading && !chats.length) && <p>No Chats Found.</p>}

                        {!isChatLoading && 
                        <div className='chat-container'>
                            {chats.map((chat: any, index: number) => {
                                return (
                                    <div key={index} className={`chat-dialog ${chat.sender === user?.id ? "right" : "left"}`}>
                                        <label className='title'>{chat.sender === user?.id ? "Local Clearing Agent" : "Transit Team"}</label>
                                        <p>{chat.message}</p>
                                        <span className='date'>{formatDateTime(chat.createdDate)}</span>
                                    </div>
                                )
                            })}
                        </div>}

                        {isChatLoading && 
                        <div className="loader">
                            <img src={loading} alt="loading" />
                            <p className="d-flex-center">Loading Chats...</p>
                        </div>}
                    </div>
                    {data?.localClearingAgentStatus !== 3 && <form onSubmit={handleSendChat}>
                    <div className="modal-footer">
                        <textarea 
                            name="comment" 
                            placeholder="Message for Transit Team..." 
                            rows={4} 
                            maxLength={300}
                            onChange={(event) => setChatData({...chatData, comment: event.target.value})}
                            value={chatData.comment} 
                            required ></textarea>
                        <button type="submit" 
                        disabled={isSubmitting || !chatData.comment}
                        className="custom-button orange">{isSubmitting ? "Loading..." : "Send"}</button>
                    </div>
                    <small style={{fontSize: "10px"}} className={chatData.comment.length >= 300 ? "mt-1 error" : "mt-1"}>{chatData.comment.length}/300 Characters</small>
                    </form>}
                </Modal>
                <Modal isOpen={isLoading} style={customStyles} className="modal modal-sm" ariaHideApp={false}>
                    <div className="loader">
                        <img src={loading} alt="loading" />
                        <p>Loading data...</p>
                    </div>
                </Modal> 
                <ToastContainer />
            </div>
            <ToastContainer />
        </Layout>
    )
}

export default ShippingDocumentsDetail